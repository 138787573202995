<!-- 优惠券 -->
<template>
  <div class="visualization-discount-coupon-container">
    <!-- 发布福袋按钮 -->
    <div class="discount-main-button">
      <div class="discount-left">
        <div class="discount-button">
          <button class="button-common" :class="active == 1 ? 'active' : ''" @click="showList(1)">福袋</button>
          <button class="button-common" :class="active == 2 ? 'active' : ''" @click="showList(2)">优惠券</button>
        </div>
      </div>
    </div>

    <div class="discount-main-info" style="margin-top: 5px" id="main-info">
      <!-- 福袋 -->
      <template v-if="active == 1">
        <div class="coupon-bag-title" style="margin-left: 41px">
          <div class="title-common" style="margin-bottom: 10px">福袋标题：</div>
          <div class="title-common" style="margin-bottom: 10px">福袋个数：</div>
          <div class="title-common" style="margin-bottom: 10px">倒计时：</div>
          <div class="title-common" style="margin-bottom: 10px">参与范围：</div>
          <div class="title-common" style="margin-bottom: 10px">参与口令：</div>
          <div class="title-common">已中奖人：</div>
        </div>
        <div class="coupon-bag-detail" style="margin-right: 41px; text-align: right">
          <div class="title-common" style="margin-bottom: 8px">{{ bagList.bag_name ? bagList.bag_name : "暂无数据" }}</div>
          <div class="title-common" style="margin-bottom: 9px">{{ bagList.bag_name ? "1" : "暂无数据" }}</div>
          <div class="title-common" style="margin-bottom: 14px">{{ bagList.countdown ? Math.floor(bagList.countdown / 60) : 0 }}分钟{{ bagList.countdown ? Math.floor(bagList.countdown % 60) : 0 }}秒</div>
          <div class="title-common" style="margin-bottom: 14px">
            {{ bagList.in_type == 1 ? "所有观众" : bagList.in_type == 2 ? "仅限粉丝" : "暂无数据" }}
          </div>
          <div class="title-common" style="margin-bottom: 14px">{{ bagList.command_name ? bagList.command_name : "暂无数据" }}</div>
          <div class="title-common" style="margin-bottom: 14px">{{ bagList.number ? bagList.number : "暂无数据" }}</div>
        </div>
      </template>
      <!-- 优惠券 -->
      <template v-if="active == 2">
        <div class="coupon-bag-title" style="margin-left: 41px">
          <div class="title-common" style="margin-top: 10px">优惠卷名称:</div>
          <div class="title-common" style="margin-top: 30px">商品名称：</div>
          <div class="title-common" style="margin-top: 30px">优惠券类型：</div>
          <div class="title-common" style="margin-top: 7px">发放量：</div>
          <div class="title-common" style="margin-top: 6px">已领取：</div>
        </div>
        <div class="coupon-bag-detail" style="margin-right: 41px; text-align: right">
          <div class="title-common" style="margin-bottom: 10px" v-if="couponOptions.length > 0">
            <el-select v-model="couponValue" placeholder="请选择" style="width: 300px" :popper-append-to-body="true" @change="showShopData">
              <el-option v-for="(item, index) in couponOptions" :key="index" :label="item.label" :value="item.id"></el-option>
            </el-select>
          </div>
          <div class="title-common" style="margin-bottom: 10px">
            <el-select v-model="shopValue" placeholder="请选择" style="width: 300px" :popper-append-to-body="true" @change="showShopInfoData">
              <el-option v-for="(item, index) in shopOptions" :key="index" :label="item.label" :value="item.id"></el-option>
            </el-select>
          </div>
          <div class="title-common" style="margin-bottom: 10px">
            {{ type ? (type == 1 ? "指定商品满减卷" : "指定商品折扣卷") : "暂无数据" }}
          </div>
          <div class="title-common" style="margin-bottom: 10px">{{ grantCount ? grantCount : "0" }}张</div>
          <div class="title-common" style="margin-bottom: 14px">{{ receive ? receive : "0" }}张</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { getBag, getCouponDrop, getCoupon } from "@/utils/apis.js";
import { createLogger } from "vuex";
export default {
  data() {
    return {
      active: 1,
      bagList: {},
      bagItem: {},
      shopOptions: [],
      couponOptions: [],
      couponValue: "",
      shopValue: "",
      resArr: [],
      type: "",
      timer: "",
      grantCount: "",
      receive: "",
    };
  },
  props: ["train", "studentInfo"],
  mounted() {
    this.getCouponDropList();
    // 福袋
    this.getBagData(6000);
  },
  methods: {
    showList(active) {
      this.active = active;
    },
    getBagData(seconds) {
      this.timer = window.setInterval(() => {
        let params = {
          user_id: this.train ? this.train.user_id : this.studentInfo ? this.studentInfo.user_id : "",
          train_id: this.train ? this.train.train_id : this.studentInfo ? this.studentInfo.train_id : "",
        };
        getBag(params).then((res) => {
          if (res.code === 200) {
            this.bagList = res.data;
          }
        });
      }, seconds);
    },
    getCouponDropList() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
      this.timer = window.setInterval(() => {
        let params = {
          user_id: this.train ? this.train.user_id : this.studentInfo ? this.studentInfo.user_id : "",
          train_id: this.train ? this.train.train_id : this.studentInfo ? this.studentInfo.train_id : "",
          room_id: this.train ? this.train.room_id : this.studentInfo ? this.studentInfo.room_id : "",
        };
        getCouponDrop(params).then((res) => {
          if (res.code === 200) {
            this.resArr = res.data;
            let arr = [];
            res.data.forEach((item) => {
              let obj = {
                id: Number(item.coupon_student_id),
                label: item.coupon_name,
                type: item.coupon_type, 
              };
              arr.push(obj);
            });
            let result=arr.find(ele=>ele.id==this.couponValue)
            if(result==undefined){
              this.couponValue=""
              this.shopValue=""
            }
            this.couponOptions.length=0
            this.couponOptions.push(...arr)
            // this.couponOptions = arr;
          }
        });
      }, 3000);
    },
    showShopData() {
      this.shopValue = "";
      console.log(this.couponValue);
      let res = this.resArr.find((ele) => ele.coupon_student_id == this.couponValue);
      console.log(res);
      this.type = res.coupon_type;
      let arr = [];
      res.children.forEach((item) => {
        let obj = {
          label: item.goods_name,
          id: item.goods_id,
        };
        arr.push(obj);
      });
      this.shopOptions = arr;
    },
    showShopInfoData() {
      // console.log(this.couponValue)
      let params = {
        coupon_student_id: this.couponValue,
        goods_id: this.shopValue,
        user_id: this.train ? this.train.user_id : this.studentInfo ? this.studentInfo.user_id : "",
        train_id: this.train ? this.train.train_id : this.studentInfo ? this.studentInfo.train_id : "",
      };
      this.grantCount = "";
      this.receive = "";
      this.getCouponInfo(params, 6000);
    },

    //选择好优惠券中商品信息后显示优惠券类型
    getCouponInfo(params, seconds) {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
      this.timer = window.setInterval(() => {
        getCoupon(params).then((res) => {
          if (res.code === 200) {
            this.grantCount = res.data.grant_count;
            this.receive = res.data.number;
          }
        });
      }, seconds);
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input--suffix .el-input__inner {
  background: #080a34;
  border-color: #080a34;
  color: #fff;
}
.visualization-discount-coupon-container {
  height: 100%;
  width: 100%;
  background: rgba(12, 14, 63, 1);
  border: 0.01rem solid rgba(12, 14, 63, 1);
  box-shadow: 0px 0px 40px 0px rgba(12, 21, 48, 0.3);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  position: relative;

  .discount-main-button {
    display: flex;
    justify-content: space-between;
    margin: 10px 0 0 25px;
    height: 50px;
    width: 500px;
    border-radius: 15px;

    .discount-left {
      display: flex;
      flex: 1;
      overflow-y: hidden;
      margin-right: 20px;
    }
    .discount-button {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #05072a;
      width: 160px;
      height: 28px;
      border-radius: 14px;
      .button-common {
        border: none;
        color: white;
        background: transparent;
        width: 80px;
        height: 28px;
        border-radius: 15px;
        cursor: pointer;
        margin-right: 5px;
      }
      .active {
        background: #4b45ff;
      }
    }
    .send {
      cursor: pointer;
      position: absolute;
      left: 94%;
    }
  }
  .discount-main-info {
    display: flex;
    justify-content: space-between;
    .coupon-bag-title,
    .coupon-bag-detail {
      .title-common {
        color: #fff;
        .el-select {
          margin: 0 !important;
        }
      }
    }
  }
}
</style>
