<template>
  <div class="data-visualization">
    <div class="data-visualization-left-container">
      <!-- 观众来源 -->
      <div
        class="data-visualization-left-container-header"
        style="height: 42%; margin-bottom: 10px"
      >
        <audienceSource :train="train" :studentInfo="studentInfo" />
      </div>
      <!-- 销量 -->
      <div
        class="data-visualization-left-container-center"
        style="height: 31.5%"
      >
        <saleVolume :train="train" :studentInfo="studentInfo" />
      </div>
      <!-- 优惠券&福袋 -->
      <div
        class="data-visualization-left-container-footer"
        style="height: 25%; margin-top: 10px"
      >
        <discountCoupon :train="train" :studentInfo="studentInfo" />
      </div>
    </div>

    <!-- 电商数据主屏 -->
    <div class="data-visualization-center-container">
      <div
        class="data-visualization-center-container-header"
        style="height: 11%"
      >
        <screenData :mainData="mainData" />
      </div>
      <div
        class="data-visualization-center-container-main"
        style="height: 40%; margin-bottom: 10px"
      >
        <screenMain :mainData="mainData" />
      </div>
      <div
        class="data-visualization-center-container-footer"
        style="height: 49.5%"
      >
        <goodsList :train="train" :studentInfo="studentInfo" />
      </div>
    </div>

    <div class="data-visualization-right-container">
      <!-- 弹幕 -->
      <div class="data-right-subtitle" style="height: 28%">
        <subtitleList :train="train" :studentInfo="studentInfo" />
      </div>
      <!-- 在线人数 -->
      <div class="data-right-online" style="height: 34%; margin-top: 10px">
        <onlineNumber :train="train" :studentInfo="studentInfo" />
      </div>
      <!-- 点赞 -->
      <div class="data-right-like" style="height: 38%; margin-top: 10px">
        <likeList :train="train" :studentInfo="studentInfo" />
      </div>
    </div>
  </div>
</template>

<script>
import audienceSource from "./modules/audienceSource";
import discountCoupon from "./modules/discountCoupon";
import saleVolume from "./modules/saleVolume";
import screenData from "./modules/screenData";
import screenMain from "./modules/screenMain";
import goodsList from "./modules/goodsList";
import subtitleList from "./modules/subtitleList";
import onlineNumber from "./modules/onlineNumber";
import likeList from "./modules/likeList";
import { getMainPanelData } from "@/utils/apis";
export default {
  components: {
    audienceSource,
    discountCoupon,
    saleVolume,
    screenData,
    screenMain,
    goodsList,
    subtitleList,
    onlineNumber,
    likeList,
    timer: null,
  },
  data() {
    return {
      train: {user_id:'',train_id:'',room_id:''},
      studentInfo:  {user_id:'',train_id:'',room_id:''},
      mainData: {},
    };
  },
  mounted() {
    if (
      this.$route.query.tid &&
      this.$route.query.t_Train_id &&
      this.$route.query.t_Room_id
    ) {
      this.train.user_id=this.$route.query.tid
      this.train.train_id=this.$route.query.t_Train_id
      this.train.room_id=this.$route.query.t_Room_id
      this.getMainPanel(3000);
    }
    if (
      this.$route.query.user_id &&
      this.$route.query.train_id &&
      this.$route.query.room_id
    ) {
      this.train.user_id=this.$route.query.user_id
      this.train.train_id=this.$route.query.train_id
      this.train.room_id=this.$route.query.room_id
      this.getMainPanel(3000);
    }
  },
  methods: {
    getMainPanel(seconds) {
      this.timer = window.setInterval(() => {
        let params = {
          user_id: this.train ? this.train.user_id : this.studentInfo?this.studentInfo.user_id:'',
          train_id: this.train ? this.train.train_id : this.studentInfo?this.studentInfo.train_id:'',
          room_id: this.train ? this.train.room_id : this.studentInfo?this.studentInfo.room_id:'',
        };
        getMainPanelData(params)
          .then((res) => {
            if (res.code === 200) {
              this.mainData = res.data;
            }
          })
          .catch(() => [
            this.$message({
              type: "error",
              message: res.msg,
            }),
          ]);
      }, seconds);
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
};
</script>

<style lang="scss" scoped>
.data-visualization {
  background: rgba(7, 9, 50, 1);
  background-size: cover;
  padding: 10px 10px 10px 8px;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: flex;
  .data-visualization-left-container {
    display: flex;
    flex-direction: column;
    width: 28%;
    margin-right: 11px;
  }
  .data-visualization-center-container {
    display: flex;
    flex-direction: column;
    width: 44%;
  }
  .data-visualization-right-container {
    display: flex;
    flex-direction: column;
    width: 27%;
    margin-left: 10px;
  }
}
</style>
